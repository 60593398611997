import { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

import { HOMEPAGE_GA_TAGS } from 'Utilities/analytics/analyticsHomePage';

import { getParsedContent } from '../../../../../../../server/wordpress/parse/parse';
import useSwiperCarousel from '../../../../fastlane/swiper/useSwiperCarousel';
import { GuideCollectionProps } from '../Guides.types';

import { Guide } from './Guide';

import styles from './GuideCarousel.scss';

type Props = GuideCollectionProps

export const GuideCarousel = ({ className, guides, onGuideClick, setGuideRef }:Props) => {
  const [isDomLoaded, setDomLoaded] = useState(false);
  const $carouselRef = useRef<HTMLElement|null>(null);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const swiperConfig: SwiperOptions = {
    on: {
      slideChange: ({ activeIndex }) => {
        const activeGuide = guides[activeIndex];
        HOMEPAGE_GA_TAGS.GUIDES_SCROLLED(
          getParsedContent(activeGuide?.title || ''),
        );
      },
    },
    slidesPerView: 'auto',
    spaceBetween: 16,
  };

  const swiper = useSwiperCarousel({
    config: {
      $el: $carouselRef.current,
      ...swiperConfig,
    },
    enableSwiper: isDomLoaded,
  }) as Swiper | undefined;

  return (
    <div
      ref={(ref) => {
        $carouselRef.current = ref;
      }}
      className={cx(styles.component, className)}
      data-testid='guideCarousel'
    >
      <ol className={cx('swiper-wrapper', { [styles.loading]: !swiper })}>
        {guides.map((guide, index) => (
          <li key={guide.id} className={cx(styles.slide, 'swiper-slide')}>
            <Guide
              ref={(ref) => ref && setGuideRef?.(ref, index)}
              onClick={() => {
                onGuideClick?.(guide);
              }}
              {...{ guide }} />
          </li>
        ))}
      </ol>
    </div>
  );
};
