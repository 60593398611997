import React from 'react';
import cx, { Argument as ClassNameType } from 'classnames';

import { wordpressImgToImgixURL } from 'Utilities';

import { Expandable } from 'Storybook/components/local/Expandable/Expandable';

import { usePageRefEvents } from '../../common/Hooks/usePageRefEvents';

import styles from './ExpandableSection.scss';

export type ContentArea = {
  answer: string;
  question: string;
}

type EventsAnalytics = {
  onButtonClick?: ({ isOpen, label }: { isOpen: boolean; label: string }) => void;
  onLoad?: () => void;
}

type Props = {
  analyticsEvents?: EventsAnalytics;
  className?: ClassNameType;
  content: ContentArea[];
  description?: string;
  imgAlt?: string;
  imgSrc?: string | React.ReactNode;
  isCompactWithoutImage?: boolean;
  onSectionLoaded?: () => void;
  reverse?: boolean;
  title: string;
};

const imgDimension = 512;

export const ExpandableSection = ({
  analyticsEvents = {},
  className,
  content,
  description = '',
  imgAlt,
  imgSrc = '',
  isCompactWithoutImage = false,
  onSectionLoaded = () => { },
  reverse = false,
  title: heading,
}: Props) => {
  const { setRef } = usePageRefEvents({
    onRefTrigger: () => {
      onSectionLoaded();
      analyticsEvents?.onLoad?.();
    },
  });

  const generateImage = () => {
    if (isCompactWithoutImage || !imgSrc) {
      return null;
    }

    if (React.isValidElement(imgSrc)) {
      return imgSrc;
    }

    return (<img
      alt={imgAlt}
      height={imgDimension}
      loading='lazy'
      src={`${wordpressImgToImgixURL(imgSrc)}&w=${imgDimension * 2}`}
      width={imgDimension}
    />);
  };

  return (
    <div
      ref={(ref) => setRef(ref)}
      className={cx(styles.component, className, { [styles.reverse]: reverse })}
      data-testid='expandable'
    >
      <div className={styles.image}>
        <p className={cx(styles.sectionTitle, { [styles.isCompactWithoutImage]: isCompactWithoutImage })}>{heading}</p>
        {generateImage()}
      </div>
      <div className={cx(styles.expandableContent, { [styles.isCompactWithoutImage]: isCompactWithoutImage })}>
        <div className={cx(styles.content, { [styles.isCompactWithoutImage]: isCompactWithoutImage })}>
          {
            content.map(({ answer, question }: ContentArea, index: number) => (
              <Expandable
                key={`expandable-section-${index}`}
                caption={question}
                id={`expandable-section-${index}`}
                onClick={(_, status) => analyticsEvents?.onButtonClick?.({ isOpen: !status, label: question })}
              >
                <div dangerouslySetInnerHTML={{ __html: answer }} />
              </Expandable>
            ))
          }
        </div>
        {description && <div dangerouslySetInnerHTML={{ __html: description }} className={styles.description} />}
      </div>
    </div>
  );
};
