import { useEffect, useRef } from 'react';
import cx from 'classnames';

import { useStopAnimationFallback } from 'Utilities/animation';

import styles from './HeroFrameAnimation.scss';

const DEFAULT_ANIMATION_DURATION = 1000;

type HeroFrameAnimationProps = {
  duration?: number;
  isAnimating: boolean;
  isMileageAnimation?: boolean;
  setIsAnimating: (isAnimating: boolean) => void;
}

export const HeroFrameAnimation = ({
  duration,
  isAnimating,
  isMileageAnimation,
  setIsAnimating,
}: HeroFrameAnimationProps) => {
  const elementRef = useRef<HTMLDivElement>(null);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const element = elementRef?.current;
    const stopProgress = () => setIsAnimating(false);

    if (element) {
      element.addEventListener('animationend', stopProgress);
      element.addEventListener('animationcancel', stopProgress);

      return () => {
        element.removeEventListener('animationend', stopProgress);
        element.removeEventListener('animationcancel', stopProgress);
      };
    }
  }, [elementRef, setIsAnimating]);

  useStopAnimationFallback(isAnimating, () => setIsAnimating(false), duration || DEFAULT_ANIMATION_DURATION);

  return <div ref={elementRef} className={cx(styles.heroFrameAnimation, {
    [styles.heroFrameAnimationMileage]: isMileageAnimation,
  })} />;
};
