import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import cx from 'classnames';

import { MDText } from 'i18n-react';

import { Button } from '@motorway/mw-highway-code';

import { timeoutFetchSentryGuard } from 'Fetch';
import cypressIds from 'CypressId';

import { ConfigContext } from 'Context/config';

import { applyCypressData } from 'Utilities/index';
import { GUIDES_PATH } from 'Utilities/wordpress';
import { getGuidesPreviewData } from 'Utilities/wordpressPages';

import { useBreakpoint } from '../../../../../components/misc/Breakpoints/Breakpoints';
import { usePageRefEvents } from '../../../common/Hooks/usePageRefEvents';
import { RoundedSection } from '../../../common/RoundedSection/RoundedSection';

import { GuideCarousel } from './subComponents/GuideCarousel';
import { GuideList } from './subComponents/GuideList';
import GuidesTexts from './Guides.texts.json';
import { GuideData } from './Guides.types';
import { getGuidesData } from './guidesHelper';

import styles from './Guides.scss';

const GuidesT = new MDText(GuidesTexts);

const t = (key: string, args?: Record<string, string>) => `${GuidesT.translate(key, args)}`;

const texts = {
  cta: t('cta'),
  details: t('details'),
  title: t('title'),
} as const;

type GuidesProps = {
  onCtaClick?: (path: string) => void;
  onGuideClick?: (guide: GuideData) => void;
  onLoad?: (label: string) => void;
  titleClassName?: string;
  wrapperClassName?: string;
};

export const Guides = ({
  onCtaClick,
  onGuideClick,
  onLoad,
  titleClassName,
  wrapperClassName,
}: GuidesProps) => {
  const history = useHistory();
  const { configState: { guidesPreviewData } } = useContext(ConfigContext);
  const [guidesData, setGuidesData] = useState(guidesPreviewData);
  const { minWidth: breakpoints } = useBreakpoint();
  const { breakpointContent } = breakpoints;

  useEffect(() => {
    const loadGuidesData = async () => {
      if (process.env.NODE_ENV === 'production') {
        try {
          return setGuidesData(await getGuidesPreviewData());
        } catch (err) {
          timeoutFetchSentryGuard(err, () => {
            window?.Sentry?.captureException?.(new Error((err instanceof Error) ? err.message : 'failed to fetch guide data'), {
              level: 'warning',
              tags: {
                fetch: 'guides',
              },
            });
          });
        }
      }

      return import('./mockGuidePreviewData').then(({ mockGuidesPreviewData }) => setGuidesData(mockGuidesPreviewData));
    };

    if (!guidesData) {
      loadGuidesData();
    }
  }, [guidesData]);

  const guides = guidesData && getGuidesData(guidesData);
  const eventLabels = (guides ?? []).map(({ title }) => title);

  const {
    $wrapper,
    entryMap: eventEntryMap,
    isSectionVisible,
    setRef: setGuideRef,
    update: updateRefEvents,
  } = usePageRefEvents({
    eventLabels,
    onRefTrigger: (label) => label && onLoad?.(label),
  });

  const GuidesComponent = !breakpointContent && isSectionVisible ? GuideCarousel : GuideList;

  useEffect(() => {
    updateRefEvents();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GuidesComponent]);

  if (!guides) {
    return null;
  }

  return <RoundedSection withBottomLedge className={styles.guidesSection} theme="primary-yellow">
    <div
      ref={$wrapper}
      className={cx(styles.container, wrapperClassName)}
      {...applyCypressData(cypressIds.sections.guides)}
    >
      <div className={styles.caption}>
        <h3 className={cx(styles.title, titleClassName)}>{texts.title}</h3>
        <h3 className={styles.details}>
          {texts.details}
        </h3>
      </div>
      <div className={styles.ctaWrapper}>
        <Button
          icon="chevron"
          label={texts.cta}
          onClick={() => {
            onCtaClick?.(GUIDES_PATH);
            history.push(GUIDES_PATH);
          }}
          variant="secondary"
        />
      </div>
      <GuidesComponent className={styles.guides} {...{ guides, onGuideClick }} setGuideRef={(ref, index) => {
        const entry = eventEntryMap.get(eventLabels[index]);
        if (!entry?.isIntersecting) {
          setGuideRef(ref, index);
        }
      }}/>
    </div>
  </RoundedSection>;
};
